import React, { useEffect, useState } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {
    PrivateRoute,
    AppAuthenticator,
    AppImpersonateUser,
} from '@manakin/authentication';
import { SnackbarMessenger, UiProvider } from '@manakin/core';
import App from '../App';
import PreviewRouter from '@manakin/app-views/PreviewRouter';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { Switch, Route, Router } from 'react-router-dom';
import { HelpButton, ColorService } from '@manakin/app-core';
let mounted = true;

const Root = (props) => {
    const { store, theme, client, history, ui } = props;
    const [colorProps, setColorProps] = useState({});

    //effect hooks
    useEffect(() => {
        mounted = true;
        return () => (mounted = false);
    }, []);

    //function
    const handleChange = (data) => {
        setTimeout(() => {
            if (mounted) {
                setColorProps(data);
            }
        }, 1);
    };

    return (
        <Provider store={store}>
            <ApolloProvider client={client}>
                <MuiThemeProvider theme={theme(colorProps)}>
                    <UiProvider ui={ui}>
                        <Router history={history}>
                            <div>
                                <SnackbarMessenger />
                                <HelpButton />
                                <ColorService onChange={handleChange}>
                                    <Switch>
                                        <Route
                                            path={'/impersonate'}
                                            component={AppImpersonateUser}
                                        />
                                        <Route
                                            path={`/preview/:elementType/:query`}
                                            component={PreviewRouter}
                                        />
                                        <Route
                                            path="/auth"
                                            component={AppAuthenticator}
                                        />
                                        <PrivateRoute
                                            path="/"
                                            component={App}
                                        />
                                    </Switch>
                                </ColorService>
                            </div>
                        </Router>
                    </UiProvider>
                </MuiThemeProvider>
            </ApolloProvider>
        </Provider>
    );
};

export default Root;
