import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme, createColor } from '@manakin/theme';
import { white, grey, mountain, black } from '../colors';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import loginBackgroundImage from '../../assets/login-background.jpg';
import accountBackgroundImage from '../../assets/account-background.jpg';
import logo from '../../assets/novaka-logo.svg';
import logoLogin from '../../assets/novaka-logo-login.svg';
import pdfIcon from '../../assets/pdf.svg';
import loginVisual from '../../assets/login-visual.svg';
import footerLogo from '../../assets/logo.svg';
import lessonBgPattern from '../../assets/element-bg-pattern.jpeg';
import calendarVisual from '../../assets/calendar-visual.svg';
import reportVisual from '../../assets/report-visual.svg';
const green = createColor('#3e9618');
const primary = createColor('#363F4A');

const theme = (obj = {}) => {
	const homePageBlockPrimaryBackgroundColor = obj.homePageBlockPrimaryBackgroundColor;
	const homePageBlockSecondaryBackgroundColor = obj.homePageBlockSecondaryBackgroundColor;

	let _obj = {};

	_obj._primary = '#363F4A';
	_obj._secondary = '#ADC4C4';
	_obj._tertiary = obj.secondaryElementColor || '#38AEA7';
	_obj._palette = obj.themeColor || '#2E3D48';
	_obj.indication = obj.indicationColor || '#ed2092';
	_obj._primaryWorkform = '#2E3D48';
	_obj._secondaryWorkform = '#ADC4C4';
	_obj._buttonBorderColor = obj.buttonBorderColor || '#000000';
	_obj.buttonRadius = obj.buttonBorderRadius === 'px' ? '40px' : obj.buttonBorderRadius || '40px';

	//dynamic colors
	_obj.defaultBox = obj.defaultBoxColor || _obj._secondary;
	_obj.defaultElement = obj.defaultWorkformColor || obj.defaultElementColor || _obj._secondary;
	_obj.primaryElement = obj.primaryElementColor || _obj._primary;
	_obj.secondaryElement = obj.secondaryElementColor || obj.defaultWorkformColor || _obj._secondaryWorkform;
	_obj.defaultWorkform = obj.defaultWorkformColor || _obj._primaryWorkform;
	_obj.primaryWorkform = obj.primaryWorkformColor || _obj._primaryWorkform;
	_obj.secondaryWorkform = obj.secondaryWorkformColor || _obj._secondaryWorkform;
	_obj.sec = obj.themeColor || '#ED2092';
	_obj.buttonBorderColor = createColor(_obj._buttonBorderColor);

	///////COLORS
	_obj.createdPalette = createColor(_obj._palette);
	_obj.indicationColor = createColor(_obj.indication);
	_obj.defaultBoxColor = createColor(_obj.defaultBox);
	_obj.secondaryPalette = createColor(_obj.sec);

	//ELEMENT COLORS
	_obj.defaultElementColor = createColor(_obj.defaultElement);
	_obj.primaryElementColor = createColor(_obj.primaryElement);
	_obj.secondaryElementColor = createColor(_obj.secondaryElement);
	_obj.defaultBookcheckElementBackground = createColor(_obj._tertiary);
	_obj.loginBackground = white;

	//WORKFORM COLORS
	_obj.defaultWorkformColor = createColor(_obj.defaultWorkform);
	_obj.primaryWorkformColor = createColor(_obj.primaryWorkform);
	_obj.secondaryWorkformColor = createColor(_obj.secondaryWorkform);
	_obj.primaryButtonColor = createColor(_obj._primary);

	//primary and secondary colors
	_obj.primaryColor = createColor(_obj._primary);
	_obj.secondaryColor = createColor(_obj._secondary);
	_obj.tertiaryColor = createColor(_obj._tertiary);
	_obj.mountainColor = createColor(mountain);

	//Palette colors (Used for buttons, icons, navigation enz)
	_obj.primaryPaletteColor = _obj.createdPalette;
	_obj.secondaryPaletteColor = _obj.secondaryPalette;

	//type colors
	_obj.typeColor = black;
	_obj.primaryTypeColor = white;
	_obj.secondaryTypeColor = black;

	//box backgroundColors
	_obj.defaultBoxBGColor = _obj.primaryColor;

	//box name colors
	_obj.defaultBoxColor = white;
	_obj.primaryBoxColor = white;
	_obj.secondaryBoxColor = black;

	//element colors
	_obj.defaultElementBGColor = _obj.defaultElementColor;
	_obj.primaryElementBGColor = _obj.primaryElementColor;
	_obj.secondaryElementBGColor = _obj.secondaryElementColor;
	_obj.defaultBookcheckColor =
		//backgroundColors
		_obj.defaultBackgroundColor = _obj.primaryColor;
	_obj.defaultTitleColor = black;
	_obj.defaultContentColor = createColor('#151b1e');
	_obj.homeLinkColor = _obj.defaultContentColor;
	_obj.secondaryBackgroundColor = grey;

	//Menu colors
	_obj.mainMenuBackground = _obj.primaryPaletteColor;
	_obj.mainMenuColor = _obj.secondaryPalette;
	_obj.mainMenuSecondaryColor = white;

	//navigation colors
	_obj.navigationColor = _obj.primaryPaletteColor;
	_obj.secondaryNavigationColor = _obj.primaryPaletteColor;
	_obj.linkColor = _obj.primaryPaletteColor;
	_obj.defaultContentHover = _obj.createdPalette;
	_obj.LessonButtonColor = black;
	_obj.defaultBorderHoverColor = _obj.createdPalette;
	_obj.primaryAppBarColors = white;
	_obj.secondaryAppBarColors = white;

	//rest
	_obj.defaultBorderColor = createColor('#E6E6E6');
	_obj.softBorderColor = createColor('#EFEFEF');
	_obj.subHeaderColor = createColor('#8A8A8A');
	_obj.correctAnswer = _obj.secondaryPalette;
	_obj.defaultBorderRadius = '0px';
	_obj.homePageBlockPrimaryBackgroundColor = createColor(homePageBlockPrimaryBackgroundColor) || obj.primaryWorkform;
	_obj.homePageBlockSecondaryBackgroundColor = createColor(homePageBlockSecondaryBackgroundColor) || obj.secondaryWorkform;

	_obj.contentWidthXL = '1600px';
	_obj.contentWidthL = '1200px';
	_obj.contentWidthM = '1050px';
	_obj.contentWidthS = '700px';
	_obj.contentWidthXS = '340px';
	//breakpoints
	_obj.smartphoneBreakpoint = '@media only screen and (min-width : 375px)';
	_obj.tabletPortraitBreakpoint = '@media only screen and (min-width : 768px)';
	_obj.tabletLandscapeBreakpoint = '@media only screen and (min-width : 1024px)';
	_obj.laptopBreakpoint = '@media only screen and (min-width : 1280px)';
	_obj.desktopBreakpoint = '@media only screen and (min-width : 1400px)';
	_obj.desktopXLBreakpoint = '@media only screen and (min-width : 2100px)';

	//margins and paddings
	_obj.mainMenuListItemPaddingTop = '25px';
	_obj.mainMenuListItemPaddingBottom = '25px';
	_obj.defaultPadding = '25px';

	//fonts
	_obj.defaultTitleFont = "'Montserrat', sans-serif";
	_obj.secondaryTitleFont = "'Montserrat', sans-serif";
	_obj.workformQuestionFont = "'Montserrat', sans-serif";
	_obj.defaultAnswerFont = "'Montserrat', sans-serif";
	_obj.defaultContentFont = "'Montserrat', sans-serif";

	_obj.workformTitleFont = {
		fontFamily: _obj.defaultTitleFont,
		fontSize: '4rem',
		lineHeight: '130%',
		letterSpacing: 0,
		fontWeight: 500,
	};

	//heights
	_obj.defaultMobileMenuHeight = '64px';
	_obj.defaultDesktopMenuHeight = '100px';

	_obj.defaultWrapper = {
		maxWidth: _obj.contentWidthM,
		position: 'relative',
		margin: '0 auto',
		width: '100%',
		paddingLeft: '25px',
		paddingRight: '25px',
	};
	_obj.extraSmallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXS,
	};
	_obj.smallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthS,
	};
	_obj.largeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthL,
	};
	_obj.extraLargeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXL,
	};

	_obj.footerLogoStyle = {
		width: '10rem',
		height: '3rem',
		backgroundImage: `url(${footerLogo})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		position: 'absolute',
		right: '10rem',
		bottom: '3rem',
	};

	_obj.registrationHeading = {
		large: {
			textAlign: 'center',
			width: '100%',
			maxWidth: '600px',
			fontSize: '40px',
			lineHeight: '130%',
			margin: '0 auto 1em',
		},
		small: {
			fontSize: '28px',
			letterSpacing: '.2rem',
		},
	};

	_obj.breakpoints = createBreakpoints({});

	const realTheme = _theme(_obj);

	return realTheme;
};

const _theme = (props) =>
	createMuiTheme({
		typography: {
			htmlFontsize: 10,
			fontFamily: [
				props.defaultContentFont,
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		palette: {
			primary: props.primaryPaletteColor,
			secondary: props.secondaryPaletteColor,
			success: {
				light: green[200],
				main: green[400],
				dark: green[600],
				contrastText: '#ffffff',
			},
			info: {
				light: blue[300],
				main: blue[400],
				dark: blue[500],
				contrastText: '#ffffff',
			},
			warning: {
				light: amber[400],
				main: amber[600],
				dark: amber[700],
				contrastText: '#ffffff',
			},
		},
		manakin: createTheme({
			accountBackgroundImage: accountBackgroundImage,
			calendarVisual: calendarVisual,
			correctAnswer: props.correctAnswer,
			defaultActiveColor: green,
			defaultAnswerFont: props.defaultAnswerFont,
			defaultBackgroundColor: props.defaultBackgroundColor,
			defaultBorderColor: props.defaultBorderColor,
			defaultBorderHoverColor: props.defaultBorderHoverColor,
			defaultBorderRadius: props.defaultBorderRadius,
			defaultBoxBGColor: props.defaultBoxBGColor,
			defaultBoxColor: props.defaultBoxColor,
			defaultButtonBorderColor: props.buttonBorderColor,
			defaultButtonRadius: props.buttonRadius,
			defaultContentColor: props.defaultContentColor,
			defaultContentFont: props.defaultContentFont,
			defaultContentHover: props.defaultContentHover,
			defaultDesktopMenuHeight: props.defaultDesktopMenuHeight,
			defaultElementBGColor: props.defaultElementBGColor,
			defaultMobileMenuHeight: props.defaultMobileMenuHeight,
			defaultPadding: props.defaultPadding,
			defaultTitleColor: props.defaultTitleColor,
			defaultTitleFont: props.defaultTitleFont,
			defaultWorkformColor: props.defaultWorkformColor,
			defaultWrapper: props.defaultWrapper,
			desktopBreakpoint: props.desktopBreakpoint,
			desktopXLBreakpoint: props.desktopXLBreakpoint,
			extraLargeWrapper: props.extraLargeWrapper,
			extraSmallWrapper: props.extraSmallWrapper,
			homeLinkColor: props.homeLinkColor,
			homePageBlockPrimaryBackgroundColor: props.homePageBlockPrimaryBackgroundColor,
			homePageBlockSecondaryBackgroundColor: props.homePageBlockSecondaryBackgroundColor,
			indicationColor: props.indicationColor,
			laptopBreakpoint: props.laptopBreakpoint,
			largeWrapper: props.largeWrapper,
			layoutButtonStyleSelected: {
				backgroundColor: props.primaryPaletteColor[500],
				border: 0,
				color: white[500],
			},
			LessonButtonColor: props.LessonButtonColor,
			linkColor: props.linkColor,
			loginBackgroundImage: loginBackgroundImage,
			loginVisual: loginVisual,
			logo: logo,
			mainMenuBackground: props.mainMenuBackground,
			mainMenuColor: props.mainMenuColor,
			mainMenuListItemPaddingBottom: props.mainMenuListItemPaddingBottom,
			mainMenuListItemPaddingTop: props.mainMenuListItemPaddingTop,
			mainMenuSecondaryColor: props.mainMenuSecondaryColor,
			navigationColor: props.navigationColor,
			pdfIcon: pdfIcon,
			primaryAppBarColors: props.primaryAppBarColors,
			primaryBoxColor: props.primaryBoxColor,
			primaryColor: props.primaryColor,
			primaryElementBGColor: props.primaryElementBGColor,
			primaryPaletteColor: props.primaryPaletteColor,
			primaryTypeColor: props.primaryTypeColor,
			primaryWorkformColor: props.primaryWorkformColor,
			reportVisual: reportVisual,
			secondaryAppBarColors: props.secondaryAppBarColors,
			secondaryBackgroundColor: props.secondaryBackgroundColor,
			secondaryBoxColor: props.secondaryBoxColor,
			secondaryColor: props.secondaryColor,
			secondaryElementBGColor: props.secondaryElementBGColor,
			secondaryNavigationColor: props.secondaryNavigationColor,
			secondaryTitleFont: props.secondaryTitleFont,
			secondaryTypeColor: props.secondaryTypeColor,
			secondaryWorkformColor: props.secondaryWorkformColor,
			smallWrapper: props.smallWrapper,
			smartphoneBreakpoint: props.smartphoneBreakpoint,
			softBorderColor: props.softBorderColor,
			subHeaderColor: props.subHeaderColor,
			tabletLandscapeBreakpoint: props.tabletLandscapeBreakpoint,
			tabletPortraitBreakpoint: props.tabletPortraitBreakpoint,
			tertiaryColor: props.tertiaryColor,
			typeColor: props.typeColor,
			underline: {
				width: '50px',
				height: '2px',
				backgroundColor: props.defaultContentColor[500],
				position: 'absolute',
				bottom: 0,
				left: '50%',
				transform: 'translate(-50%, 2.5rem)',
			},
			visual: {
				backgroundImage: `url(${loginVisual})`,
				width: '100%',
				height: '100%',
				backgroundSize: 'cover',
			},
			workformTitleFont: props.workformTitleFont,
		}),
		overrides: {
			AccordionFaq: {
				panelRoot: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				content: {
					color: 'black',
				},
				panelHeading: {
					color: 'black',
				},
			},
			AppAccount: {
				deleteButton: {
					color: 'red',
				},
				confirmDeleteButton: {
					backgroundColor: 'red',
				},
			},
			AppAccountWrapper: {
				root: {
					background: 'none',
				},
				title: {
					letterSpacing: '0rem',
				},
			},
			AppBar: {
				menuText: {
					color: white[500],
				},
				appBarBackground: {
					backgroundColor: props.primaryColor[500],
				},
				// toolBarRoot: {
				//     backgroundColor: props.primaryColor[500],
				// },
				dropdownInput: {
					fontSize: '1.4rem',
					letterSpacing: '.3rem',
					fontFamily: props.secondaryTitleFont,
					fontWeight: 'bold',
					color: 'white',
				},
				menuButtonLabel: {
					fontSize: '2rem',
					fontWeight: 500,
					marginLeft: '22px',
				},
				dropdownIcon: {
					color: props.primaryAppBarColors[500],
				},
				iconColor: {
					color: props.primaryAppBarColors[500],
				},
				buttonRoot: {
					marginLeft: '1rem',
					color: props.primaryAppBarColors[500],
				},
				buttonLabel: {
					color: props.primaryAppBarColors[500],
				},
				secondaryColors: {
					'& $menuText': {
						color: props.secondaryAppBarColors[500],
					},
					'& $iconColor': {
						color: props.secondaryAppBarColors[500],
					},
					'& $backButtonRoot': {
						color: props.secondaryAppBarColors[500],
					},
					'& $buttonLabel': {
						color: props.secondaryAppBarColors[500],
					},
				},
				backButtonRoot: {
					color: 'white',
				},
			},
			appBestTimes: {
				user: {
					backgroundColor: props.primaryColor[500],
				},
				body: {
					color: white[500],
				},
				currentUser: {
					backgroundColor: props.primaryPaletteColor[500],
					'& $body': {
						color: white[500],
					},
					'& $time': {
						color: white[500],
					},
				},
			},
			AppBettingGameFrontPageBody: {
				root: {
					backgroundColor: white[500],
				},
			},
			AppBettingGameQuestions: {
				points: {
					backgroundColor: props.primaryPaletteColor[500],
					borderRadius: '100%',
				},
			},
			AppBookCheckHeader: {
				headingRoot: {
					letterSpacing: 0,
				},
			},
			AppBox: {
				footerLogo: {
					right: '12rem',
					bottom: '-39px',
				},
			},
			AppBoxCard: {
				backgroundImage: {
					transform: 'scale(1.1)',
				},
				inner: {
					borderRadius: props.defaultBorderRadius,
					[props.breakpoints.up('md')]: {
						height: '40rem',
						padding: '0 6rem',
					},
					[props.breakpoints.down('md')]: {
						height: '40rem',
					},
					'&:hover': {
						'& $backgroundImage': {
							transform: 'translateX(2%) scale(1.1)',
						},
						'& $overflow': {
							transform: 'scale(1) translate(2%, -20px)',
						},
						'& $boxDescription': {
							visibility: 'visible',
							transform: 'none',
							opacity: 1,
							[props.breakpoints.down('md')]: {
								maxHeight: '40rem',
							},
						},
						'& $boxSubtitle': {
							display: 'none',
						},
						'& $boxName': {
							opacity: 0,
							visibility: 'hidden',
							transform: 'translateY(-32px)',
						},
						'& $boxCardLabel': {
							transform: 'none',
						},
					},
				},
				innerDone: {
					[props.breakpoints.up('md')]: {
						height: '30rem',
					},
				},
				boxCardLabel: {
					position: 'absolute',
					top: '0px',
					padding: '0px 14px',
					left: '0',
					zIndex: '99',
					fontSize: '10px',
					lineHeight: '22px',
					[props.breakpoints.up('md')]: {
						top: 0,
						padding: '4px 14px',
						fontSize: '16px',
						lineHeight: '28px',
					},
				},
				boxCardIconLabel: {
					backgroundColor: white[500],
				},
				boxDescription: {
					position: 'absolute',
					marginTop: 0,
					visiblity: 'hidden',
					opacity: 0,
					transform: 'translateY(32px)',
					transition: 'all .2s ease',
					[props.breakpoints.down('md')]: {
						background: 'rgba(0,0,0,0.8)',
						lineHeight: 1.2,
						left: 0,
						top: 0,
						padding: '20px',
						height: '100%',
					},
				},
				boxCardText: {
					color: black[500],
					[props.breakpoints.up('md')]: {
						fontSize: '1.4rem',
					},
				},
				listView: {
					borderRadius: 0,
					'& $boxName': {
						letterSpacing: 0,
						[props.breakpoints.up('md')]: {
							letterSpacing: 0,
						},
					},
					'&:hover': {
						'& $boxName': {
							opacity: 1,
							transform: 'translateY(0)',
							visibility: 'visible',
						},
					},
				},
				percentage: {
					fontSize: '1.4rem',
					letterSpacing: 0,
					width: '4rem',
					height: '4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '1.4rem',
						width: '4rem',
						height: '4rem',
					},
				},
				percentageDone: {
					backgroundColor: black[500],
					color: white[500],
				},
			},
			AppBoxes: {
				wrapper: {
					maxWidth: '1250px',
				},
				smallWrapper: {
					backgroundColor: 'transparent',
					[props.breakpoints.down('md')]: {
						backgroundColor: 'white',
						paddingTop: '20px',
					},
				},
			},
			AppBoxHeader: {
				root: {
					backgroundColor: props.defaultBoxBGColor[500],
					[props.breakpoints.up('md')]: {
						paddingBottom: '15rem',
					},
				},
				heading: {
					color: props.defaultBoxColor[500],
					textTransform: 'uppercase',
					'&::after': {
						backgroundColor: props.defaultBoxColor[500],
					},
				},
				body: {
					color: props.defaultBoxColor[500],
				},
				resultsPercentage: {
					'&::after': {
						background: `linear-gradient(90deg, rgba(252,122,76,1) 0%, rgba(252,122,76,1) 25%, rgba(252,122,76,0) 25%, rgba(252,122,76,0) 75%, rgba(252,122,76,1) 75%, rgba(252,122,76,1) 100%)`,
						letterSpacing: 0,
						width: '150px',
						color: props.primaryPaletteColor[500],
					},
				},
			},
			AppBoxLayout: {
				wrapper: {
					[props.breakpoints.up('md')]: {
						paddingBottom: '4rem',
					},
				},
				root: {
					position: 'relative',
					paddingBottom: '10rem',
					[props.breakpoints.up('md')]: {
						marginTop: '-28.5rem',
						paddingBottom: '14rem',
					},
				},
				footerLogo: {
					...props.footerLogoStyle,
					backgroundImage: 'none',
					right: '12rem',
				},
				listView: {
					backgroundColor: 'transparent',
					paddingBottom: '0',
				},
				noBoxesMessage: {
					color: 'white',
				},
				link: {
					color: 'white',
				},
			},
			AppCaseContent: {
				closeButtonRedo: {
					backgroundColor: props.primaryWorkformColor[100],
					color: black[500],
					'&:hover': {
						backgroundColor: props.primaryWorkformColor[50],
						color: black[500],
					},
				},
				closeButtonClose: {
					backgroundColor: props.primaryWorkformColor[500],
					color: white[500],
					'&:hover': {
						backgroundColor: props.primaryWorkformColor[400],
						color: white[500],
					},
				},
			},
			AppCheckQuestion: {
				formControlRoot: {
					backgroundColor: 'white',
				},
			},
			AppChoiceQuestion: {
				header: {
					fontFamily: props.workformQuestionFont,
				},
				subHeader: {
					fontFamily: props.secondaryTitleFont,
					color: black[500],
					fontSize: '1.4rem',
					padding: '0 0 .75rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
					},
					'&::after': {
						backgroundColor: props.indicationColor[500],
					},
				},
			},
			AppClassList: {
				topBar: {
					backgroundColor: '#363f4a',
				},
				downloadReport: {
					color: '#fff'
				}
			},
			AppContentHeader: {
				title: {
					letterSpacing: '0rem',
				},
			},
			AppDasboardFilterSelectField: {
				formControlLabel: {
					color: 'white',
				},
				listItemText: {
					color: 'white',
				},
				menuItem: {
					backgroundColor: props.primaryPaletteColor[900],
					'&:nth-child(even)': {
						backgroundColor: props.primaryPaletteColor[700],
					},
				},
				select: {
					'&:before': {
						borderColor: 'white',
					},
					'&:after': {
						borderColor: 'white',
					},
				},
				icon: {
					fill: 'white',
				},
			},
			AppDashboardHeader: {
				root: {
					[props.breakpoints.up('md')]: {
						padding: '18rem 0 30rem',
					},
				},
				body: {
					[props.breakpoints.down('md')]: {
						marginBottom: '4.8rem',
					},
				},
			},
			AppDownloadButton: {
				wrapper: {
					maxWidth: '1020px',
				},
				content: {
					backgroundColor: 'white',
				},
			},
			AppElementCard: {
				videoBG: {
					maxHeight: '410px',
					marginBottom: '-10px',
				},
				contentColor: {},
				backgroundImage: {
					transition: 'width .3s, height .3s, background-color .3s',
				},
				root: {
					borderRadius: 0,
					// overflow: 'visible',
					'&:hover': {
						'& $backgroundImage': {
							[props.breakpoints.up('md')]: {
								transform: 'scale(1)',
								height: 'calc(100% + 30px)',
							},
						},
					},
				},
				name: {
					letterSpacing: 0,
					fontWeight: 'bold',
					fontFamily: props.groldBold,
					[props.breakpoints.up('md')]: {
						fontSize: '2.4rem',
						letterSpacing: 0,
						lineHeight: '2.4rem',
					},
				},
				type: {
					marginBottom: '1rem',
					fontSize: '1.8rem',
					fontWeight: 'normal',
				},
				body: {},
				bodytitle: {
					fontSize: '1.8rem',
					fontWeight: 'bold',
					lineHeight: '2rem',
					marginBottom: '1rem',
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
						letterSpacing: 0,
						lineHeight: '2rem',
						fontWeight: 'bold',
					},
				},
				bookCheck: {
					backgroundColor: props.defaultBookcheckElementBackground[500],
					[props.breakpoints.up('md')]: {
						minHeight: '20rem',
					},
				},
				bookCheckButton: {
					color: props.defaultBookcheckElementBackground.contrastText,
					borderColor: props.defaultBookcheckElementBackground.contrastText,
				},
				bookCheckContent: {
					'& $bodytitle': {
						color: props.defaultBookcheckElementBackground.contrastText,
						fontSize: '1.8rem',
						fontWeight: 'bold',
						lineHeight: '2rem',
						marginBottom: '1rem',
					},
					'& $body': {
						margin: 0,
						lineHeight: '1.25',
						color: props.defaultBookcheckElementBackground.contrastText,
					},
				},
				button: {
					backgroundColor: 'transparent',
					color: 'white',
					border: `3px solid white`,
					minHeight: '3rem',
					padding: '1.8rem!important',
					'& span': {
						fontSize: '1.8rem',
					},
				},
				done: {
					backgroundColor: 'white',
					[props.breakpoints.up('md')]: {
						width: '4rem',
						height: '4rem',
					},
				},
				doneIcon: {
					color: 'black',
				},
				primaryTitleColor: {
					color: white[500],
				},
				bridgeContent: {
					[props.breakpoints.up('md')]: {
						padding: '80px 60px',
					},
					'& $contentColor': {
						'&>ul': {
							paddingLeft: '2rem',
							marginTop: '2rem',
							[props.breakpoints.up('md')]: {
								paddingLeft: '2rem',
							},
						},
					},
				},
				fullWidth: {
					[props.breakpoints.up('md')]: {
						'&$bookCheck': {
							'& $backgroundImage': {
								width: '20rem',
							},
							padding: '2.5rem 5rem 2.5rem 24rem',
						},
					},
				},
				playIconContainer: {
					display: 'none',
				},
				minHeight: {
					[props.breakpoints.up('md')]: {
						minHeight: '400px',
					},
					'& $done': {
						[props.breakpoints.up('md')]: {
							top: '2rem',
							right: '2rem',
						},
					},
				},
			},
			AppElementOverlay: {
				title: {
					fontFamily: props.defaultTitleFont,
					fontWeight: 'bold',
				},
				closeButton: {
					backgroundColor: black[500],
					color: 'white',
					'&:hover': {
						backgroundColor: black[500],
						color: 'white',
					},
				},
			},
			AppElementsRoot: {
				grid: {
					maxWidth: '1230px',
					marginTop: '-13rem',
					[props.breakpoints.down('md')]: {
						marginTop: '0rem',
					},
				},
			},
			AppFactcardReferral: {
				root: {
					borderRadius: props.defaultBorderRadius,
					maxWidth: '1200px',
					margin: '0 auto',
				},
				title: {
					fontSize: '2.4rem',
					marginBottom: '1rem',
				},
				body: {
					lineHeight: 1.25,
				},
				multi: {
					'& $title': {
						marginBottom: '.4rem',
					},
					'& $body': {
						marginBottom: '3rem',
					},
				},
				button: {
					backgroundColor: 'transparent',
					border: '3px solid white',
					minHeight: '3rem',
					padding: '1.8rem!important',
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
			},
			AppFactcardsCard: {
				root: {
					borderRadius: props.defaultBorderRadius,
				},
				name: {
					letterSpacing: 0,
					fontWeight: 500,
					fontFamily: props.groldBold,
					[props.breakpoints.up('md')]: {
						fontSize: '3.2rem',
						letterSpacing: 0,
						lineHeight: '5rem',
					},
				},
				bodytitle: {
					fontSize: '1.8rem',
					fontWeight: 'bold',
					lineHeight: '2rem',
					marginBottom: '1rem',
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
						letterSpacing: 0,
						lineHeight: '2rem',
						fontWeight: 'bold',
					},
				},
				backgroundImage: {},
				bookCheck: {
					'& $backgroundImage': {
						[props.breakpoints.up('md')]: {
							width: '20rem',
						},
					},
					[props.breakpoints.up('md')]: {
						height: '20rem',
						padding: '2.5rem 5rem 2.5rem 24rem',
					},
				},
				bookCheckContent: {
					'& $body': {
						margin: 0,
					},
				},
				button: {
					backgroundColor: 'transparent',
					minHeight: '3rem',
					padding: '1.8rem!important',
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
				factCardsButton: {
					borderColor: props.buttonBorderColor[500],
				},
			},
			AppFirstPage: {
				wrapper: {
					maxWidth: props.contentWidthM,
				},
				titleRoot: {
					[props.breakpoints.up('md')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						letterSpacing: '.4rem',
					},
					[props.breakpoints.up('lg')]: {
						fontSize: '6rem',
						lineHeight: '7rem',
						letterSpacing: '.4rem',
					},
				},
			},
			AppGroupsDropdown: {
				selectColors: {
					color: props.primaryAppBarColors[500],
					'&::before': {
						borderColor: 'white',
					},
				},
			},
			AppHotspotMapLandscape: {
				subHeader: {
					fontFamily: props.secondaryTitleFont,
					color: black[500],
					fontSize: '1.4rem',
					padding: '0 0 .75rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
					},
				},
				question: {
					fontFamily: props.workformQuestionFont,
				},
				container: {
					maxWidth: '1200px',
					margin: '0 auto',
				},
			},
			AppImageAndTextMPC: {
				card: {
					[props.breakpoints.up('md')]: {
						marginBottom: '16px',
					},
				},
			},
			AppImageWithSubtitle: {
				image: {
					maxWidth: '1020px',
					margin: '0 auto',
				},
				content: {
					maxWidth: '1200px',
					margin: '0 auto',
				},
			},
			AppLayoutButtons: {
				label: {
					color: props.tertiaryColor[500],
					textTransform: 'uppercase',
					fontFamily: props.groldBold,
					letterSpacing: '3px',
					display: 'none',
				},
				tabRoot: {
					borderColor: 'white',
					color: 'white',
				},
				tabsRoot: {
					marginBottom: '4rem',
				},
				tabSelected: {
					backgroundColor: black[500],
				},
			},
			AppLesson: {
				root: {
					backgroundImage: `url(${lessonBgPattern})`,
				},
				largeWrapper: {
					maxWidth: '1230px',
					[props.breakpoints.up('md')]: {
						maxWidth: '1230px',
					},
				},
				normalMargin: {
					margin: '3rem 0',
					[props.breakpoints.up('md')]: {
						margin: '8rem 0',
					},
				},
			},
			AppLessonBottom: {
				root: {
					backgroundColor: white[500],
					borderTop: `1px solid ${props.defaultBorderColor[500]}`,
				},
				button: {
					margin: '0 1rem',
					minWidth: '300px',
					fontFamily: props.defaultTitleFont,
					backgroundColor: props.secondaryColor[500],
					[props.breakpoints.up('md')]: {
						height: '34rem',
					},
					'&:hover': {
						backgroundColor: props.secondaryColor[700],
					},
				},
				backToBoxButton: {
					display: 'none',
				},
				redoQuestionButton: {
					display: 'flex',
					color: white[500],
				},
				nextQuestionButton: {
					display: 'flex',
				},
			},
			AppLessonHeader: {
				root: {
					backgroundColor: props.primaryColor[500],
				},
				fontStyle: {
					textTransform: 'uppercase',
					fontSize: '22px',
					fontFamily: props.defaultContentFont,
				},
				heading: {
					fontSize: '6rem',
					letterSpacing: '1.2px',
				},
			},
			AppLessonIntroduction: {
				content: {
					'&:first-letter': {
						color: props.defaultTitleColor[500],
						fontSize: '2rem',
						lineHeight: '3.6rem',
						fontWeight: '600',
						'&:first-letter': {
							fontSize: '12rem',
							lineHeight: '12rem',
							margin: '0 6rem 0 0',
						},
					},
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
						lineHeight: '2.8rem',
						minHeight: '154px',
					},
				},
			},
			AppLogin: {
				link: {
					fontWeight: 'bold',
				},
			},
			appLoginHeader: {
				root: {
					backgroundColor: props.loginBackground[500],
				},
				visualTitle: {
					display: 'none',
					[props.breakpoints.up('lg')]: {
						display: 'none',
					},
				},
				visual: {
					display: 'none',
				},
				logo: {
					width: '315px',
					height: '65px',
					position: 'static',
					backgroundImage: `url(${logoLogin})`,
					backgroundSize: 'contain',
					backgroundRepeat: 'no-repeat',
					marginTop: '24px',
					backgroundOrigin: 'content-box',
					backgroundPosition: 'center',
				},
			},
			AppLogout: {
				root: {
					backgroundColor: 'white',
				},
			},
			AppMenu: {
				link: {
					color: white[500],
				},
			},
			AppMoodboard: {
				root: {
					maxWidth: '1200px',
					margin: '0 auto',
				},
				card: {
					padding: '2rem',
					[props.breakpoints.up('md')]: {
						padding: '2rem',
					},
				},
				content: {
					maxHeight: '410px',
					overflow: 'hidden',
					[props.breakpoints.up('md')]: {
						maxHeight: '396px',
					},
				},
				text: {
					fontSize: '1.6rem',
					lineHeight: '2rem',
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
						lineHeight: '3rem',
					},
				},
			},
			AppOrderQuestion: {
				card: {
					backgroundColor: 'white',
				},
			},
			AppPointsInfo: {
				pointsBody: {
					color: props.primaryPaletteColor.contrastText,
				},
				pointsTitle: {
					color: props.primaryPaletteColor.contrastText,
				},
			},
			appPointsPopup: {
				heading: {
					paddingRight: '30px',
				},
				nextCategory: {
					color: props.defaultContentColor[500],
				},
				points: {
					backgroundColor: props.primaryPaletteColor[500],
					borderRadius: '100%',
				},
				pointsBody: {
					color: props.primaryPaletteColor.contrastText,
				},
			},
			AppProfilePicture: {
				editPicture: {
					color: white[500],
				},
			},

			AppProgramsDropdown: {
				selectColors: {
					color: props.primaryAppBarColors[500],
					'&::before': {
						borderColor: 'white',
					},
				},
			},
			AppRadioGroup: {
				formControlRoot: {
					backgroundColor: 'white',
				},
				formControlLabel: {
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
					},
				},
			},
			AppRegistrationAuthData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationPersonalData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationSchoolClassData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationSchoolData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationScreen: {
				root: {
					minHeight: '100vh',
					backgroundColor: white[500],
				},
				heading: {
					textAlign: 'center',
				},
			},
			AppRegistrationSuccess: {
				content: {
					margin: '0 0 0px',
				},
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppResumeButton: {
				body1: {
					fontWeight: '700',
					fontSize: '1.4rem',
				},
				root: {
					backgroundColor: white[500],
					overflow: 'hidden',
					'&:hover': {
						'& $body1': {
							color: props.createdPalette[500],
						},
						'& $longArrow': {
							'& .arrow': {
								backgroundColor: props.createdPalette[500],
							},
						},
					},
				},
			},
			AppSearchInput: {
				root: {
					margin: 0,
				},
			},
			AppSimpleSelect: {
				formControlLabel: {
					color: 'white',
				},
				listItemText: {
					color: 'white',
				},
				select: {
					'&:after': {
						borderColor: 'white',
					},
				},
				menuItem: {
					'& $listItemText': {
						color: 'black',
					},
					'&:nth-child(even)': {
						'& $listItemText': {
							color: 'black',
						},
					},
				},
			},
			AppSliderInput: {
				sliderLabel: {
					fontSize: '16px',
				},
			},
			AppSortSlider: {
				questionContent: {
					[props.breakpoints.up('md')]: {
						fontSize: '1.8rem',
					},
				},
			},
			AppStatementQuestion: {
				subHeader: {
					fontFamily: props.secondaryTitleFont,
					color: black[500],
					fontSize: '1.4rem',
					padding: '0 0 .75rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
					},
				},
				root: {
					borderRadius: props.defaultBorderRadius,
					overflow: 'hidden',
					maxWidth: '1200px',
					margin: '0 auto',
				},
				quote: {
					fontFamily: props.defaultTitleFont,

					fontSize: '2.75rem',
					lineHeight: '1.25',
					letterSpacing: '1px',
				},
				button: {
					backgroundColor: 'transparent',
					border: '3px solid white',
					minHeight: '3rem',
					padding: '1.8rem!important',
					borderRadius: props.buttonBorderRadius,
					[props.breakpoints.up('md')]: {
						minWidth: '17rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
			},
			AppStatisticsBox: {
				statistics: {
					[props.breakpoints.up('md')]: {
						border: `1px solid black`,
					},
				},
				specificStatisticItem: {
					border: `1px solid ${props.primaryPaletteColor[100]}`,
					[props.breakpoints.up('md')]: {
						border: 0,
					},
				},
				linkContent: {
					color: 'white',
				},
				statisticsTitle: {
					color: 'white',
				},
				stasticsBody: {
					color: 'white',
				},
			},
			AppTeacherHeader: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				heading: {
					color: props.defaultTitleColor[500],
				},
				body: {
					color: props.defaultTitleColor[500],
				},
			},
			AppTeacherProgram: {
				root: {
					backgroundColor: props.secondaryBackgroundColor[500],
				},
				title: {
					letterSpacing: '0rem',
				},
			},
			AppTeacherReport: {
				root: {
					backgroundColor: '#363f4a',
				},
				content: {
					'& $heading': {
						color: 'white',
					},
				},
			},
			AppTestCard: {
				root: {
					borderRadius: props.defaultBorderRadius,
				},
			},
			AppTitleAndText: {
				wrapper: {
					[props.breakpoints.up('lg')]: {
						padding: '0 9rem',
					},
				},
				root: {
					borderRadius: props.defaultBorderRadius,
					maxWidth: '1200px',
					margin: '0 auto',
					[props.breakpoints.up('md')]: {
						padding: '10rem 0',
					},
				},
				noBG: {
					backgroundColor: 'transparent',
				},
				header: {
					textTransform: 'none',
					fontSize: '30px',
					lineHeight: '28px',
					paddingBottom: '2.4rem',
				},
				content: {
					fontSize: '1.8rem',
					lineHeight: '3rem',
				},
			},
			AppTitleAndTextImage: {
				root: {
					maxWidth: '1200px',
					margin: '0 auto',
				},
				header: {
					textTransform: 'none',
					fontSize: '30px',
					lineHeight: '28px',
					paddingBottom: '2.4rem',
				},
				content: {
					fontSize: '1.8rem',
					lineHeight: '2.8rem',
				},
			},
			AppVideoPlayer: {
				playButton: {
					'& $icon': {
						backgroundColor: props.primaryPaletteColor[500],
					},
				},
			},
			AppWhatIsWhatItem: {
				arrow: {
					[props.breakpoints.up('md')]: {
						'&:hover': {
							backgroundColor: `${props.correctAnswer[500]}!important`,
						},
					},
				},
			},
			AppWorkformLayout: {
				header: {
					fontWeight: '700',
					marginBottom: '7rem',
					fontSize: '3.6rem',
					marginTop: '1.8rem',
					fontFamily: props.workformQuestionFont,
					color: black[500],
					lineHeight: '4.6rem',
					[props.breakpoints.down('xs')]: {
						fontSize: '3rem',
						lineHeight: '4rem',
					},
				},
				subHeader: {
					fontFamily: props.secondaryTitleFont,
					color: black[500],
					fontSize: '1.4rem',
					padding: '0 0 .75rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
					},
				},
				secondaryColors: {
					'& $button': {
						color: white[500],
					},
				},
				container: {
					maxWidth: '1020px',
				},
			},
			AuthenticationCmsAuthenticator: {
				root: {
					backgroundColor: props.loginBackground[500],
				},
			},
			AuthenticationConfirmReset: {
				root: {
					background: white[500],
				},
			},
			AuthenticationResetPassword: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			ClaimLicense: {
				root: {
					width: '100%',
					height: '100%',
				},
			},
			HeaderFaq: {
				heading: {
					letterSpacing: '0rem',
				},
			},
			HelpButton: {
				icon: {
					marginTop: 0,
				},
			},
			ManakinLoginForm: {
				root: {
					backgroundColor: primary[500],
				},
			},
			MuiBadge: {
				colorSecondary: {
					backgroundColor: 'red',
				},
			},
			MuiButton: {
				root: {
					zIndex: 1,
					color: 'white',
					height: 'auto',
					padding: '20px',
					borderRadius: props.buttonRadius,
				},
				contained: {
					boxShadow: 'none',
				},
				containedPrimary: {
					backgroundColor: black[500],
					color: 'white',
					transition: 'background-color .2s, color .2s',
					'&:hover': {
						backgroundColor: '#2d2d2d',
						color: 'white',
					},
				},
				label: {
					position: 'relative',
					textTransform: 'none',
					zIndex: 1,
					fontSize: '1.8rem',
					textDecoration: 'none',
				},
				outlinedPrimary: {
					border: '2px solid black',
					padding: '18px',
					color: 'black',
					'&:hover': {
						border: '2px solid black',
					},
				},
			},
			MuiChip: {
				label: {
					fontSize: '14px',
				},
			},
			MuiDialog: {
				paper: {
					margin: '18px',
					[props.breakpoints.up('md')]: {
						padding: '4.4rem 5rem 1rem',
					},
				},
				paperFullWidth: {
					minHeight: '53rem',
					borderRadius: 0,
				},
			},
			MuiDialogActions: {
				root: {
					justifyContent: 'center',
					flexDirection: 'column',
					[props.breakpoints.up('md')]: {
						margin: 0,
					},
				},
				action: {
					width: '100%',
					padding: '2rem',
				},
			},
			MuiDialogContent: {
				root: {
					[props.breakpoints.up('md')]: {
						padding: 0,
					},
				},
			},
			MuiDialogTitle: {
				root: {
					[props.breakpoints.up('md')]: {
						padding: 0,
					},
					'&>h2': {
						marginBottom: '4rem',
					},
				},
			},
			MuiDrawer: {
				paper: {
					padding: '3rem',
					fontFamily: props.defaultContentFont,
					fontSize: '1.8rem',
					color: props.mainMenuColor,
					background: props.mainMenuBackground[500],
					[props.breakpoints.up('md')]: {
						padding: '1.8rem 4rem',
						fontSize: '3rem',
					},
				},
			},
			MuiFab: {
				primary: {
					backgroundColor: black[500],
					color: white[500],
					'&:hover': {
						backgroundColor: black[400],
					},
				},
			},
			MuiFormControl: {
				root: {
					width: '100%',
				},
			},
			MuiFormControlLabel: {
				root: {
					marginRight: '20px',
					width: '40%',
					'@media (min-width: 1400px)': {
						width: 'auto',
						marginRight: '50px',
					},
				},
			},
			MuiFormGroup: {
				root: {
					width: '100%',
					flexDirection: 'row',
				},
			},
			MuiFormHelperText: {
				root: {
					fontSize: '1.25rem',
				},
			},
			MuiFormLabel: {
				root: {
					fontFamily: props.defaultContentFont,
					fontSize: '1.8rem',
					color: props.defaultContentColor[500],
				},
			},
			MuiInput: {
				input: {
					padding: '14px 0',
					fontSize: '1.8rem',
					lineHeight: '3rem',
				},
			},
			MuiInputBase: {
				inputMultiline: {
					minHeight: '14rem',
				},
			},
			MuiMenuItem: {
				root: {
					fontSize: '1.8rem',
				},
			},
			MuiPickersDay: {
				daySelected: {
					'& >*': {
						margin: 0,
						marginTop: '16px',
					},
				},
				day: {
					'& >*': {
						margin: 0,
						marginTop: '16px',
					},
				},
			},
			MuiPickersSlideTransition: {
				transitionContainer: {
					'& >*': {
						top: '-6px',
					},
				},
			},
			MuiRadio: {
				colorPrimary: {
					color: 'black',
					'&$checked': {
						color: 'black',
					},
				},
			},
			MuiSelect: {
				icon: {
					top: 'calc(50% - 10px)',
				},
			},
			MuiStepConnector: {
				root: {
					display: 'none',
				},
			},
			MuiStepIcon: {
				root: {
					transform: 'scale(1.7)',
					margin: '0 10px',
				},
				text: {
					fontSize: '1.2rem',
				},
			},
			MuiStepLabel: {
				iconContainer: {
					padding: '0 20px 0 0',
				},
				labelContainer: {
					display: 'none',
				},
			},
			MuiStepper: {
				root: {
					backgroundColor: 'transparent',
					padding: '0',
					margin: '30px 0',
				},
			},
			MuiSvgIcon: {
				root: {
					width: '2rem',
					height: '2rem',
				},
			},
			MuiTableCell: {
				root: {
					padding: '4px 16px 4px 16px',
				},
				head: {
					border: 0,
					fontSize: '1.2rem',
					color: props.defaultContentColor[500],
					fontFamily: props.defaultTitleFont,
					lineHeight: '2rem',
					letterSpacing: '.4rem',
				},
				body: {
					paddingTop: '1.8rem',
					paddingBottom: '1.8rem',
				},
			},
			MuiTablePagination: {
				caption: {
					fontSize: '1.8rem',
				},
				select: {
					fontSize: '1.8rem',
					lineHeight: '1.8rem',
					paddingRight: 32,
				},
			},
			MuiTypography: {
				root: {
					fontSize: '1.8rem',
				},
				h1: {
					fontFamily: props.defaultTitleFont,
					fontSize: '3rem',
					fontWeight: '700',
					lineHeight: '4.2rem',
					color: props.defaultTitleColor[500],
					marginBottom: '1.4rem',
					letterSpacing: 0,
					[props.breakpoints.up('md')]: {
						fontSize: '7rem',
						lineHeight: '9rem',
						letterSpacing: '1rem',
					},
				},
				h2: {
					fontFamily: props.defaultTitleFont,
					fontSize: '2.75rem',
					lineHeight: '3.3rem',
					fontWeight: '700',
					color: props.defaultContentColor[500],
					letterSpacing: '0.3rem',
					marginBottom: '1.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '5.2rem',
						lineHeight: '7rem',
					},
				},
				h3: {
					fontFamily: props.defaultContentFont,
					fontSize: '3rem',
					lineHeight: '100%',
					color: props.defaultContentColor[500],
					fontWeight: 700,
					marginBottom: '1.4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '4rem',
					},
				},
				h4: {
					color: props.defaultContentColor[500],
					fontFamily: props.secondaryTitleFont,
					fontWeight: 500,
					fontSize: '3.6rem',
					lineHeight: '5rem',
					marginBottom: '3.6rem',
					[props.breakpoints.up('md')]: {
						lineHeight: '6rem',
						marginBottom: '4.6rem',
						fontSize: '3.6rem',
					},
				},
				h5: {
					color: props.defaultContentColor[500],
					fontFamily: props.secondaryTitleFont,
					fontSize: '2.6rem',
					fontWeight: 'bold',
					lineHeight: '3.6rem',
					marginBottom: '1.3rem',
					[props.breakpoints.up('md')]: {
						lineHeight: '5.2rem',
						marginBottom: '0rem',
					},
				},
				h6: {
					color: props.defaultContentColor[500],
					fontFamily: props.defaultTitleFont,
					fontSize: '1.8rem',
					fontWeight: 'bold',
					lineHeight: '2rem',
					marginBottom: '1.3rem',
					letterSpacing: '.2rem',
					[props.breakpoints.up('md')]: {
						marginBottom: '1.8rem',
					},
				},
				subtitle1: {
					fontFamily: props.secondaryTitleFont,
					fontSize: '2.2rem',
					lineHeight: '150%',
					color: props.defaultContentColor[500],
					marginBottom: '1.4rem',
					fontWeight: '500',
				},
				subtitle2: {
					fontFamily: props.secondaryTitleFont,
					fontSize: '2.4rem',
					lineHeight: '150%',
					color: props.defaultContentColor[500],
					marginBottom: '1.4rem',
					fontWeight: '500',
					[props.breakpoints.up('md')]: {
						marginBottom: '4rem',
					},
				},
				body1: {
					fontSize: '1.4rem',
					color: props.defaultContentColor[500],
					lineHeight: '3rem',
					marginBottom: '2rem',
					[props.breakpoints.up('md')]: {
						marginBottom: '3rem',
						fontSize: '1.8rem',
					},
				},
				body2: {
					fontSmoothing: 'auto',
					color: props.defaultContentColor[500],
					fontSize: '1.5rem',
					lineHeight: '3rem',
					marginBottom: '2rem',
					[props.breakpoints.up('md')]: {
						fontSize: '2rem',
						lineHeight: '4rem',
						fontWeight: 400,
					},
				},
				gutterBottom: {
					marginBottom: '3em',
				},
			},
			PrivateValueLabel: {
				label: {
					fontSize: '14px',
				},
			},

			// appLoginHeader: {
			//     root: {
			//         background: props.primaryColor[500],
			//         [props.breakpoints.up('lg')]: {
			//             background:
			//                 'linear-gradient(to right, #ffffff 0%,#ffffff 25%,#363F4A 25%,#363F4A 100%)',
			//         },
			//     },
			//     content: {
			//         backgroundPosition: 'center bottom',
			//         backgroundSize: '745px',
			//     },
			//     login: {
			//         backgroundColor: 'white',
			//         [props.breakpoints.up('md')]: {
			//             maxWidth: '500px',
			//         },
			//     },
			//     visual: {
			//         display: 'none',
			//     },

			//     visualTitle: {
			//         color: 'black',
			//         [props.breakpoints.up('md')]: {
			//             transformOrigin: 'left center',
			//             transform: 'rotate(-90deg)',
			//             fontSize: '3.6rem',
			//             left: '50%',
			//             bottom: '7rem',
			//             marginLeft: '-680px',
			//         },
			//     },
			//     AppBoxes: {
			//         wrapper: {
			//             maxWidth: '1250px',
			//         },
			//         smallWrapper: {
			//             backgroundColor: 'transparent',
			//             [props.breakpoints.down('md')]: {
			//                 backgroundColor: 'white',
			//                 paddingTop: '20px',
			//             },
			//         },
			//     },
			//     AppBoxCard: {
			//         backgroundImage: {
			//             transform: 'scale(1.1)',
			//         },
			//         inner: {
			//             borderRadius: props.defaultBorderRadius,
			//             [props.breakpoints.up('md')]: {
			//                 height: '40rem',
			//                 padding: '0 6rem',
			//             },
			//             '&:hover': {
			//                 '& $backgroundImage': {
			//                     transform: 'translateX(2%) scale(1.1)',
			//                 },
			//                 '& $overflow': {
			//                     transform: 'scale(1) translate(2%, -20px)',
			//                 },
			//                 '& $boxDescription': {
			//                     visibility: 'visible',
			//                     transform: 'none',
			//                     opacity: 1,
			//                 },
			//                 '& $boxSubtitle': {
			//                     display: 'none',
			//                 },
			//                 '& $boxName': {
			//                     opacity: 0,
			//                     visibility: 'hidden',
			//                     transform: 'translateY(-32px)',
			//                 },
			//                 '& $boxCardLabel': {
			//                     transform: 'none',
			//                 },
			//             },
			//         },
			//         innerDone: {
			//             [props.breakpoints.up('md')]: {
			//                 height: '30rem',
			//             },
			//         },
			//         boxName: {
			//             fontWeight: '500',
			//             letterSpacing: '7px',
			//             fontFamily: props.groldBold,
			//             textTransform: 'uppercase',
			//             transition: 'all .2s ease',
			//             color: primary[500],
			//             [props.breakpoints.up('md')]: {
			//                 fontSize: '4rem',
			//                 lineHeight: '4rem',
			//                 maxWidth: '50%',
			//             },
			//         },
			//         boxCardLabel: {
			//             color: white[500],
			//             [props.breakpoints.up('md')]: {
			//                 top: '40px',
			//             },
			//         },
			//         boxCardIconLabel: {
			//             backgroundColor: white[500],
			//         },
			//         boxDescription: {
			//             position: 'absolute',
			//             marginTop: 0,
			//             visiblity: 'hidden',
			//             opacity: 0,
			//             transform: 'translateY(32px)',
			//             transition: 'all .2s ease',
			//             color: primary[500],
			//         },
			//         boxCardText: {
			//             color: black[500],
			//             [props.breakpoints.up('md')]: {
			//                 height: '56px',
			//                 fontSize: '1.4rem',
			//             },
			//         },
			//         listView: {
			//             '& $boxName': {
			//                 letterSpacing: 0,
			//                 [props.breakpoints.up('md')]: {
			//                     letterSpacing: 0,
			//                 },
			//             },
			//             '&:hover': {
			//                 '& $boxName': {
			//                     opacity: 1,
			//                     transform: 'translateY(0)',
			//                     visibility: 'visible',
			//                 },
			//             },
			//         },
			//         percentage: {
			//             fontSize: '1.75rem',
			//             letterSpacing: 0,
			//             width: '4rem',
			//             height: '4rem',
			//             [props.breakpoints.up('md')]: {
			//                 fontSize: '1.75rem',
			//                 width: '4rem',
			//                 height: '4rem',
			//             },
			//         },
			//         percentageDone: {
			//             backgroundColor: black[500],
			//             color: white[500],
			//         },
			//     },
			// },
		},
	});

export default theme;
