import logo from '../assets/logo.svg';
const defaultLang = 'nl_NL';

export default async (props) =>
	await new Promise(async (resolve) => {
		const config = {
            whiteLabel: 'Novaka',
            defaultLang: defaultLang,
            files: [
                {
                    id: 'testfile',
                    file: logo,
                },
            ],
            pages: {
                appLogin: {
                    showHeaderTitle: false,
                    headerTitleIsHtml: false,
                    showFootNote: false,
                    showLogo: true,
                    visualTitle: true,
                },
                appRegistration: {
                    showBirthDate: false,
                    showPhoneNumber: false,
                    showRegisterType: false,
                    showRegisterCode: false,
                    registerCodeRequired: false,
                    addMoreLicenses: true,
                    showLanguageSelect: true,
                },
                appNoLicense: {
                    showFootNote: false,
                },
                appAccount: {
                    showEducation: true,
                    hideOrganisation: true,
                    showLanguageSelect: true,
                    hideFunction: true,
                },
                box: {
                    scrollTo: false,
                },
                boxCard: {
                    showDescription: true,
                },
                dashboard: {
                    scrollTo: false,
				},
				appElementOverlay: {
					showHeaderTitle: true,
				},
			},
			elementCardConfig: {
				noPercentage: true
			},
			caseConfig: {
				redoButtons: true,
			},
			assignmentConfig: {
				redoButtons: true,
			},
			general: {
				hideTimer: false,
				downloadRapportage: true,
				showTags: true,
				playButtonAsIcon: true,
                cbd: false,
                showLogo: true,
                showScrollIndicatorText: true
            },
            customColors: {
                accountAppBar: '#353F4A',
            },
            getFile: (id) => config.files.find((file) => file.id === id),
            getPage: (key) => config[key],
        };

		resolve(config);
	});
